import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'


function Main() {

  const [active, setActive] = useState();
  const [Nav, setNav] = useState(false);
  const [Home, setHome] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    closeMenu();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {active &&
        <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
      }
      <header className={`fixed ${menuOpen ? 'menu-open' : ''}`}>
      
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            
          <div className='logo'>

          <Link to="/">  <img style={{width: "100px", height: "100px"}} src={logo} alt="image" /></Link>
          </div>
          
            
         
            
     
            <button className={`navbar-toggler ${menuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon" onClick={() => setHome(Home === true ? false : true)}>
                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              
              <ul className="navbar-nav ml-auto">
                <li className="nav-item has_dropdown">
                  
                  <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                  Ana Sayfa
                  </Link>
                 
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link" to="/hakkimizda" onClick={handleMenuItemClick}>
                    Hakkımızda
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sirketlerimiz" onClick={handleMenuItemClick}>
                    ŞİRKETLERİMİZ
                  </Link>
                </li>
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="/urunlerimiz" onClick={handleMenuItemClick}>
                  ÜRÜNLERİMİZ
                  </Link>
                 
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link" to="/iletisim" onClick={handleMenuItemClick}>
                    İLETİŞİM
                  </Link>
                </li>
                
                
              </ul>
            </div>

          </nav>
        </div>
      </header>

      
    </>
  )
}

export default Main