import React from 'react'
import { Link } from 'react-router-dom'


function ContactUS() {
  return (
    <>
      <section className="contact_us_section">
       
        <div className="container">
          <div className="section_title text-center">
            
            <span className="sub_text mb-1" data-aos="fade-up" data-aos-duration={1500} >
              İletişim
            </span>
            <h2 data-aos="fade-up" data-aos-duration={1500}>
            Bize Ulaşın
            </h2>
          </div>
          <div className="row iletisimbox">
         
         
            <div className="col-md-6 ">
              <div className="contact_box">
                <ul className="contact_listing" data-aos="fade-up" data-aos-duration={1500} >
                  <li>
                    <p className="sub_title">Telefon</p>
                    <div className="cnt_block">
                      <Link to="tel:+905353553304">+90 535 355 33 04</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                   
                  </li>
                  <li>
                    <p className="sub_title">Email </p>
                    <div className="cnt_block">
                      <Link to="mailto:">info@xlfood.org</Link>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Adres</p>
                    <div className="cnt_block">
                      <p>Pirömer, İnönü Cd. No:40, 42000 Ereğli/Konya, Türkiye</p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                  </li>
                </ul>
             
               
              </div>
            </div>
          </div>
          <div className="map">
         
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6329.836740042021!2d34.048116098172635!3d37.50984338819329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d79714939de687%3A0x2f737f9323159e84!2zWcO2cmVzZWwgRXQgQXTDtmx5ZXNp!5e0!3m2!1sen!2s!4v1708731457402!5m2!1sen!2s"
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
        <div className='business'>
        <iframe src='https://widgets.sociablekit.com/google-business-profile/iframe/25370516' frameborder='0' width='100%' height='600px'></iframe>

          <iframe src='https://widgets.sociablekit.com/google-business-profile/iframe/25370534' frameborder='0' width='100%' height='600px'></iframe>
          <iframe src='https://widgets.sociablekit.com/google-business-profile/iframe/25370536' frameborder='0' width='100%' height='600px'></iframe>
        </div>
       
      </section>
    </>
  )
}

export default ContactUS