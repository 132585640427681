import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';
import galleryone from '../../assets/images/abtgallery_01.png'
import gallerytwo from '../../assets/images/abtgallery_02.png'
import gallerythree from '../../assets/images/abtgallery_03.png'
import galleryfour from '../../assets/images/abtgallery_04.png'
import galleryfive from '../../assets/images/abtgallery_05.png'
import gallerysix from '../../assets/images/abtgallery_06.png'
import galleryseven from '../../assets/images/abtgallery_07.png'
import galleryeight from '../../assets/images/abtgallery_08.png'
import gallerynine from '../../assets/images/abtgallery_09.png'
import galleryten from '../../assets/images/abtgallery_10.png'
import galleryeleven from '../../assets/images/abtgallery_11.png'
import gallerytwelve from '../../assets/images/abtgallery_12.png'
import hpazar from '../../assets/images/hpazar.svg'
import yoresel from '../../assets/images/yoreselet.svg'
import vacuum from '../../assets/images/vacuum.png'
import toptanet from '../../assets/images/toptanet.svg'
import buffalo from '../../assets/images/buffalo.svg'
import manya from '../../assets/images/manya.svg'


function Gallery() {

  const [images, setImages] = useState([
    galleryone,
    gallerytwo,
    gallerythree,
    galleryfour,
    galleryfive,
    gallerysix,
    galleryseven,
   

  ])

  const [img, setImg] = useState()
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()
  const [img5, setImg5] = useState()
  const [img6, setImg6] = useState()
  const [img7, setImg7] = useState()
  const [img8, setImg8] = useState()
  const [img9, setImg9] = useState()
  const [img10, setImg10] = useState()
  const [img11, setImg11] = useState()

  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1)



  return (
    <>
      <section className="row_am gallery_section">
     
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
               <a href="/sirketlerimiz">
               <img className="img-fluid" src={hpazar} alt="img" />
               </a>
                
              
                
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
              <a href="/sirketlerimiz">
              <img className="img-fluid" src={yoresel} alt="img" />
                  </a>
             
               
              </div>
            </div>
            
            
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
              <a href="/sirketlerimiz">
              <img className="img-fluid" src={toptanet} alt="img" />
                  </a>
          
                
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
              <a href="/sirketlerimiz">
              <img className="img-fluid" src={vacuum} alt="img" />
                  </a>
        
                
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
              <a href="/sirketlerimiz">
              <img className="img-fluid" src={buffalo} alt="img" />
                  </a>
              
                
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
              <a href="/sirketlerimiz">
              <img className="img-fluid" src={manya} alt="img" />
                  </a>
              
               
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
    </>
  )
}

export default Gallery