import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Dish01 from '../../assets/images/toptanet.svg'
import Dish02 from '../../assets/images/yoreselet.svg'
import Dish03 from '../../assets/images/hpazar.svg'
import Dish04 from '../../assets/images/manya.svg'
import Dish05 from '../../assets/images/buffalo.svg'
import Dish06 from '../../assets/images/vacuum.png'




const Popular = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

function PopularDish() {
  return (
    <>
      <section className="row_inner_am popular_dish_section">
       
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">XL FOOD</span>
            <br />
            <h1>Dünyasını Keşfedin!</h1>
            <p>Sektöründe lider konumda olan dokuz farklı gıda firmasını bünyesinde barındırmaktadır. Her biri alanında uzmanlaşmış bu firmalar, geniş ürün yelpazesiyle müşterilerine benzersiz lezzet deneyimleri sunmaktadır.</p>
          </div>
          <div id="popular_dish" className="dishList_slider" data-aos="fade-in" data-aos-duration={1500} >
            <Slider className="Slider-dishes" {...Popular} slidesToShow={4} arrows={false} margin={15} autoplay={true} autoplaySpeed={1000}>
             
             <a href="https://www.toptanetmarket.com" target='blank_'><div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish01} alt="img" />
                  </div>
                  <h3>Toptan Et Market</h3>
                </div>
              </div></a>
              
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish02} alt="img" />
                  </div>
                  <h3>Yöresel Et Atölyesi</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish03} alt="img" />
                  </div>
                  <h3>Hayvan Pazarından</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish04} alt="img" />
                  </div>
                  <h3>Tavuk Manya</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish06} alt="img" />
                  </div>
                  <h3>Vacu-um Food</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src={Dish05} alt="img" />
                  </div>
                  <h3>Buffalo Burger</h3>
                </div>
              </div>
            

              
            </Slider>
          </div>
          <div className="text-center mb-md-4">
            <Link to="/about" className="btn btn_primary">
              Daha Fazla...
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default PopularDish