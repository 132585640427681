import React from "react";
import { Link } from "react-router-dom";

import chefimg01 from '../../assets/images/ceo.png'
import cv from '../../assets/images/cv.png'
import vizyon from '../../assets/images/vizyon.png'
import v1 from '../../assets/images/v1.png'
import v2 from '../../assets/images/v2.png'



function Chef() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
       
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="chef_img">
                <img className="radius_img img-fluid" src={chefimg01} alt="img" />
              </div>
              <h3 className="chef_name">
                <span>Kurucu</span> Önder Kahraman
              </h3>
            </div>
            <div className="col-md-6">
              <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
              
                <div className="section_title">
                  <span className="sub_text">Kurucumuz</span>
                  <h2 className="mb-3">
                  Önder KAHRAMAN<br /> 
                  </h2>
                  
                  <p>
                  Türkiye ve Uluslarası ticaret ağlarında danışmanlık, üst düzey yöneticlik ve saha da aktif çalışmaları
                  sayesinde sektörde öncü bir isim olarak devam etmektedir.
                 
                  Kurucumuz Önder Kahraman’ın katkı sağladığı ve gelişim sürecinde destek verdiği firmalar;
                  DAIKIN KLİMA
NOKTA LTD,
TR DOKSAN A.Ş.,
AKTİF TEMİZLİK LTD,
ORC İŞ GÜVENLİĞİ HİZMETLERİ LTD,
ERAN MÜHENDİSLİK PROJE LTD,
DEFNE BİLGİ İŞLEM LTD,
İNTAYA İNŞAAT A.Ş.,
ERSAN BİNA VE TESİS YÖNETİM LTD,
ER-SA PLASTİK LTD,
ÜNMAK MAKİNA VE GEMİ SANAYİ LTD,
ELİT SOSYAL HİZMETLER LTD,
ODEMAK İŞ GÜVENLİĞİ LTD,
SULTAN VADİ LTD,
DİNÇ MAKİNA KLİMA LTD,
DEKORAL ALÜMİNYUM LTD,
FATİH ÖZEL GÜVENLİK A.Ş.,
ÖZBEK TEKER SANAYİ VE TİCARET A.Ş.,
İLKİM AMBALAJ VE KİMYA LTD,
FESTIVALPAZARI.COM,
TAKMODA,
HIRADERM,
                  </p>
                 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cv">
                  <img className="cvimg" src={vizyon} alt="" />
                  </div>
                  <div className="cv">
                  <img className="cvimg" src={v1} alt="" />
                  </div>
                  <div className="cv">
                  <img className="cvimg" src={v2} alt="" />
                  </div>
                 
      <div className="cv">
                  <img className="cvimg" src={cv} alt="" />
                  </div>
                 
    </>
  );
}

export default Chef;
