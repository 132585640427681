import React from 'react'
import AboutImg from '../../assets/images/abtbanshape.png'
import hpazar from '../../assets/images/hpazar.svg'
import yoresel from '../../assets/images/yoreselet.svg'
import vacuum from '../../assets/images/vacuum.png'
import toptanet from '../../assets/images/toptanet.svg'
import buffalo from '../../assets/images/buffalo.svg'
import manya from '../../assets/images/manya.svg'

function AbtBanner() {
  return (
    <>
      <section className="abt_banner">
        
        <div className="section_title text-center">
         
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
          Xl food{" "}
          </span>
          <br />
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
         XL FOOD'un zengin ve çeşitlilik dolu dünyasına  Hoş geldiniz !
          </h2>
          <br />
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          XL FOOD, sektöründe lider konumda olan dokuz farklı gıda firmasını bünyesinde barındırmaktadır.
           Her biri alanında uzmanlaşmış bu firmalar,
            geniş ürün yelpazesiyle müşterilerine benzersiz lezzet deneyimleri sunmaktadır.
          </p>
        </div>
        <div className="abt_img">
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={yoresel} alt="img" />
          </div>
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={hpazar} alt="img" />
          </div>
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={hpazar} alt="img" />
          </div>
          
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={vacuum} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={toptanet} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={buffalo} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={manya} alt="img" />
          </div>
        </div>
      </section>
    </>
  )
}

export default AbtBanner