import React from 'react'
import m1 from '../../assets/images/m1.png'
import m2 from '../../assets/images/m2.png'
import m3 from '../../assets/images/m3.png'
import m4 from '../../assets/images/m4.png'
import m5 from '../../assets/images/m5.png'
import m6 from '../../assets/images/m6.png'
import m7 from '../../assets/images/m7.png'
import m8 from '../../assets/images/m8.png'
import m9 from '../../assets/images/m9.png'
import m10 from '../../assets/images/m10.png'

function C2() {
  return (
    <>
      <section className="row_am coustomer_review_section">
        <div className="container">
         
                    
      <div className="cv">
                  <img className="cvimg" src={m1} alt="" />
                  </div>

               
                  <div className="cv">
                  <img className="cvimg" src={m2} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m3} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m4} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m5} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m6} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m7} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m8} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m9} alt="" />
                  </div>
                                 
      <div className="cv">
                  <img className="cvimg" src={m10} alt="" />
                  </div>

        </div>
        
      </section>
    </>
  )
}

export default C2