import React from 'react'
import hpazar from '../../assets/images/hpazar.svg'
import yoresel from '../../assets/images/yoreselet.svg'
import vacuum from '../../assets/images/vacuum.png'
import toptanet from '../../assets/images/toptanet.svg'
import buffalo from '../../assets/images/buffalo.svg'
import tavukmanya from '../../assets/images/manya.svg'








function CustomerReview() {
  return (
    <>
      <section className="row_am coustomer_review_section">
        <div className="container">
          <div className="coustomer_inner">
        
              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={hpazar} alt="img" />
                </div>
                <div className="review">
                  <h1 className='sirket'> Hayvanpazarından.com</h1>
                  <p>
                  Hayvanpazarindan.com, çiftçi ve tüketicileri online platformda buluşturarak adil ticareti teşvik eder. Tarım ve gıda sektöründe dijitalleşmeyi destekleyerek, üreticilerin ve tüketicilerin bir araya gelmesini sağlarız.
                  </p>
                 
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>

              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={yoresel} alt="img" />
                </div>
                <div className="review">
                <h1 className='sirket'> YÖRESEL ET ATÖLYESİ</h1>
                 
                 <p>
                 Yöresel Et Atölyesi, Konya’da bulunan kırmızı et ve kümes hayvanlarının tedariğini sağlar. Kaliteli et ürünleriyle yerel pazarlarda güvenilir bir tedarikçi olarak hizmet veririz.

                 </p>
                 
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>

              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={vacuum} alt="img" />
                </div>
                <div className="review">
                 
                <h1 className='sirket'> VACU-UM FOOD</h1>
                <p>
                Vacu-um Food, restaurantlarda servis edilen her ürünü son pişirme ve servis edilecek halinde her bir parçasını ayrı ayrı vakumlayarak, online sipariş edildikten sonra aynı tazelik ve lezzeti evinize getirir. Yüksek kaliteli vakum teknolojisi ile müşterilerimize taze ve hijyenik bir deneyim sunarız.
                </p>
                 
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>

              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={toptanet} alt="img" />
                </div>
                <div className="review">
                 
                <h1 className='sirket'> TOPTAN ET MARKET</h1>
                 <p>Toptan Et Market, Türkiye’den Avrupa’ya uzanan geniş ağıyla kaliteli et ürünlerini dünyaya taşır. Müşterilerimize çeşitli et ürünleri ve kesimleri sunarak, profesyonel işletmelere güvenilir bir tedarikçi olma misyonuyla hareket ederiz.
</p>
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>

              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={buffalo} alt="img" />
                </div>
                <div className="review">
                <h1 className='sirket'> TEXAS BUFFALO BURGER</h1>
                 <p>
                 Texas Buffalo Burger, eşsiz tatlarla dolu burgerlerle damakları şenlendirir. İnovatif burger çeşitleri ve lezzetli soslarla müşterilerimize benzersiz bir burger deneyimi sunarız.
                 </p>
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>

              <div  className="review_box with_text" data-aos="fade-up" data-aos-duration={1500} >
                <span className="quotes left_quote">
                  <i className="icofont-quote-left" />
                </span>
                <div className="user_img">
                  <img src={tavukmanya} alt="img" />
                </div>
                <div className="review">
                <h1 className='sirket'> TAVUKMANYA</h1>
                  <p>
                  Tavukmanya, doğal ve taze tavuk ürünleriyle hızlı yemek servisi, sağlık ve lezzet getirir. Dengeli menü ürünleriyle, müşterilerimize güvenilir ve kaliteli bir seçenek sunarız.


                  </p>
                 
                </div>
                <span className="quotes right_quote">
                  <i className="icofont-quote-right" />
                </span>
              </div>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerReview